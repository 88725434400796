import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "components/layout"
import JoinOurTeam from "components/JoinOurTeam/JoinOurTeam"
import { Section } from "components/Layouts"
import Video from "../components/Video/Video"
import Sizzle from "../videos/sizzle-short.mp4"

import SEO from "components/seo"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

const CareersPage = ({ data, path }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { hero, joinOurTeam, pageEnding } = post
  const { heading } = joinOurTeam
  const jobOpenings = data.allJobs.nodes
  const renderCareerHero = () => {
    const { heading, topText, bottomText } = hero

    return (
      <Section className="career-hero__container container">
        <h1 className="career-hero__heading">{heading}</h1>
        <div className="career-hero__text-container">
          <p className="career-hero__text">{topText}</p>
          <p className="career-hero__text">{bottomText}</p>
        </div>
      </Section>
    )
  }

  const renderBottomBanner = () => {
    const { heading, topText, bottomText, iframeSrc } = pageEnding

    return (
      <Section className="career-bottom-banner__container container">
        <h2 className="career-bottom-banner__heading">{heading}</h2>
        <div className="career-bottom-banner__text-container">
          <span className="career-bottom-banner__top-text">{topText}</span>
          <br />
          <span className="career-bottom-banner__bottom-text">
            <MarkdownViewer markdown={bottomText} />
          </span>
        </div>
      </Section>
    )
  }

  return (
    <Layout pageTitle="careers-page" path={path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />
      {renderCareerHero()}
      <div className="sizzle-container">
        <Video src={Sizzle} />
      </div>
      <JoinOurTeam heading={heading} jobOpenings={jobOpenings} />
      {renderBottomBanner()}
    </Layout>
  )
}

export default CareersPage

export const pageQuery = graphql`
  query careerPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          heading
          bottomText
          topText
        }
        joinOurTeam {
          heading
        }
        pageEnding {
          heading
          iframeSrc
          topText
          bottomText
        }
      }
    }
    allJobs: allJobsJson {
      nodes {
        jobTitle
        queryBlurb
        title
        hasThisPage
      }
    }
  }
`
