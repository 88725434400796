import React from "react"
import { Link } from "gatsby"
import { Section } from "../Layouts"

const JoinOurTeam = ({ heading, jobOpenings }) => {
  const renderJobs = () => {
    return jobOpenings.map((job, i) => {
      const { jobTitle, queryBlurb, title, hasThisPage } = job
      return (
        <div key={i} className="job-item__container">
          {hasThisPage ? (
            <Link to={`/${title}`}>
              <h3 className="job-item__title">{jobTitle}</h3>
            </Link>
          ) : (
            <h3 className="job-item__title">{jobTitle}</h3>
          )}
          <div className="job-item__description-container">
            <p className="job-item__description">{queryBlurb}</p>
          </div>
        </div>
      )
    })
  }

  return (
    <div className="join-our-team__background">
      <Section className="join-our-team__container container">
        <h2 className="join-our-team__heading">{heading}</h2>
        {renderJobs()}
      </Section>
    </div>
  )
}

export default JoinOurTeam
